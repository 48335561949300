// core components
import {
    Apps, Business,
    Dashboard as DashboardIcon,
    Language,
    Person,
    Send,
    Settings,
    VpnKey
} from "@material-ui/icons";
import Login from "views/Login.js";
import Dashboard from "views/admin/Dashboard";
import UsersProfile from "views/admin/Users/UsersProfile";
import UserList from "views/admin/Users/UserList";
import UserEdit from "views/admin/Users/UserEdit";
import LanguageList from "views/admin/Settings/LanguageList";
import LanguageEdit from "views/admin/Settings/LanguageEdit";
import ChannelList from "views/admin/Channels/ChannelList";
import ChannelEdit from "views/admin/Channels/ChannelEdit";
import CustomerList from "views/admin/Customers/CustomerList";
import CustomerEdit from "views/admin/Customers/CustomerEdit";
import SystemList from "views/admin/Systems/SystemList";
import SystemEdit from "views/admin/Systems/SystemEdit";
import SystemTokenEdit from "views/admin/Systems/Tokens/SystemTokenEdit";
import NotificationList from "views/admin/NotificationList";
import ChannelPriceEdit from "./views/admin/Channels/ChannelPriceEdit";

export default function routes(intl) {
    return [
        {
            path: "/login",
            name: "Login",
            icon: VpnKey,
            iconColor: "Info",
            component: Login,
            layout: "/auth",
        },
        /*{
          path: "/register",
          name: "Register",
          icon: AccountCircle,
          iconColor: "ErrorLight",
          component: Register,
          layout: "/auth",
        },*/
        {
            path: "/user-profile",
            name: "User Profile",
            icon: Person,
            component: UsersProfile,
            layout: "/admin",
            hidden: true,
        },
        {
            path: "/index",
            name: intl.formatMessage({
                id: "menu.dashboard",
                defaultMessage: "Dashboard",
            }),
            icon: DashboardIcon,
            iconColor: "Primary",
            component: Dashboard,
            layout: "/admin",
            views: []
        },
        {
            path: "/customers",
            name: intl.formatMessage({id: "menu.customers.list", defaultMessage: "Customers"}),
            icon: Business,
            component: CustomerList,
            layout: "/admin",
            roles: ["sysdev", "admin"],
            views: [
                {
                    path: "/create",
                    name: intl.formatMessage({id: "menu.customers.create", defaultMessage: "Create Customer"}),
                    component: CustomerEdit,
                    layout: "/admin",
                    hidden: true,
                    roles: ["sysdev", "admin"],
                },
                {
                    path: "/:customer_id",
                    name: intl.formatMessage({id: "menu.customers.edit", defaultMessage: "Edit Customer"}),
                    component: CustomerEdit,
                    layout: "/admin",
                    hidden: true,
                    roles: ["sysdev", "admin"],
                    views: [
                        {
                            path: "/systems/create",
                            name: intl.formatMessage({id: "menu.customer.systems.create", defaultMessage: "Create System"}),
                            component: SystemEdit,
                            layout: "/admin",
                            hidden: true,
                            roles: ["sysdev", "admin"],
                        },
                        {
                            path: "/systems/:system_id",
                            name: intl.formatMessage({id: "menu.customer.system.edit", defaultMessage: "Edit System"}),
                            component: SystemEdit,
                            layout: "/admin",
                            hidden: true,
                            roles: ["sysdev", "admin"],
                            views: [
                                {
                                    path: "/tokens/create",
                                    name: intl.formatMessage({id: "menu.customer.system.system_tokens.create", defaultMessage: "Create System Token"}),
                                    component: SystemTokenEdit,
                                    layout: "/admin",
                                    hidden: true,
                                    roles: ["sysdev", "admin"],
                                },
                                {
                                    path: "/tokens/:token_id",
                                    name: intl.formatMessage({id: "menu.customer.system.system_tokens.edit", defaultMessage: "Edit System Token"}),
                                    component: SystemTokenEdit,
                                    layout: "/admin",
                                    hidden: true,
                                    roles: ["sysdev", "admin"],
                                },
                            ]
                        },
                    ]
                },
            ]
        },
        {
            path: "/systems",
            name: intl.formatMessage({id: "menu.systems.list", defaultMessage: "Systems"}),
            icon: Apps,
            component: SystemList,
            layout: "/admin",
            roles: ["sysdev", "admin"],
            views: [
                {
                    path: "/create",
                    name: intl.formatMessage({id: "menu.systems.create", defaultMessage: "Create System"}),
                    component: SystemEdit,
                    layout: "/admin",
                    hidden: true,
                    roles: ["sysdev", "admin"],
                },
                {
                    path: "/:system_id",
                    name: intl.formatMessage({id: "menu.systems.edit", defaultMessage: "Edit System"}),
                    component: SystemEdit,
                    layout: "/admin",
                    hidden: true,
                    roles: ["sysdev", "admin"],
                    views: [
                        {
                            path: "/tokens/create",
                            name: intl.formatMessage({id: "menu.system_tokens.create", defaultMessage: "Create Token"}),
                            component: SystemTokenEdit,
                            layout: "/admin",
                            hidden: true,
                            roles: ["sysdev", "admin"],
                        },
                        {
                            path: "/tokens/:token_id",
                            name: intl.formatMessage({id: "menu.system_tokens.edit", defaultMessage: "Edit Token"}),
                            component: SystemTokenEdit,
                            layout: "/admin",
                            hidden: true,
                            roles: ["sysdev", "admin"],
                        },
                    ]
                },
            ]
        },
        {
            path: "/notifications",
            name: intl.formatMessage({id: "menu.notifications.list", defaultMessage: "Notifications"}),
            icon: Send,
            component: NotificationList,
            layout: "/admin",
            roles: ["sysdev", "admin"]
        },
        {
            path: "/settings",
            name: intl.formatMessage({
                id: "menu.settings",
                defaultMessage: "Settings",
            }),
            icon: Settings,
            iconColor: "Info",
            layout: "/admin",
            roles: ["sysdev", "admin"],
            views: [
                {
                    path: "/users",
                    name: intl.formatMessage({
                        id: "menu.users.list",
                        defaultMessage: "Users",
                    }),
                    icon: Person,
                    iconColor: "Error",
                    component: UserList,
                    layout: "/admin",
                    views: [
                        {
                            path: "/:id",
                            name: intl.formatMessage({
                                id: "menu.users.edit",
                                defaultMessage: "Edit user",
                            }),
                            component: UserEdit,
                            hidden: true
                        },
                        {
                            path: "/create",
                            name: intl.formatMessage({
                                id: "menu.users.create",
                                defaultMessage: "Create user",
                            }),
                            component: UserEdit,
                            hidden: true
                        },
                    ],
                },
                {
                    path: "/channels",
                    name: intl.formatMessage({id: "menu.channels.list", defaultMessage: "Channels"}),
                    icon: Apps,
                    component: ChannelList,
                    layout: "/admin",
                    roles: ["sysdev", "admin"],
                    views: [
                        {
                            path: "/create",
                            name: intl.formatMessage({id: "menu.channels.create", defaultMessage: "Create Channel"}),
                            component: ChannelEdit,
                            layout: "/admin",
                            hidden: true,
                            roles: ["sysdev", "admin"],
                        },
                        {
                            path: "/:channel_id",
                            name: intl.formatMessage({id: "menu.channels.edit", defaultMessage: "Edit Channel"}),
                            component: ChannelEdit,
                            layout: "/admin",
                            hidden: true,
                            roles: ["sysdev", "admin"],
                            views: [
                                {
                                    path: "/prices/create",
                                    name: intl.formatMessage({id: "menu.channels.create", defaultMessage: "Create Price"}),
                                    component: ChannelPriceEdit,
                                    layout: "/admin",
                                    hidden: true,
                                    roles: ["sysdev", "admin"],
                                },
                                {
                                    path: "/prices/:price_id",
                                    name: intl.formatMessage({id: "menu.channels.create", defaultMessage: "Edit Price"}),
                                    component: ChannelPriceEdit,
                                    layout: "/admin",
                                    hidden: true,
                                    roles: ["sysdev", "admin"],
                                },
                            ]
                        },
                    ]
                },
                {
                    path: "/languages",
                    name: intl.formatMessage({id: "menu.languages.list", defaultMessage: "Languages"}),
                    icon: Language,
                    component: LanguageList,
                    views: [
                        {
                            path: "/create",
                            name: intl.formatMessage({id: "menu.languages.create", defaultMessage: "Create Language"}),
                            component: LanguageEdit,
                            hidden: true,
                        },
                        {
                            path: "/:id",
                            name: intl.formatMessage({id: "menu.languages.edit", defaultMessage: "Edit Language"}),
                            component: LanguageEdit,
                            hidden: true,
                        },
                    ]
                },
            ]
        },
    ];
}


