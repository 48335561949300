import React from "react";
import {Grid} from "@material-ui/core";
import {Apps} from "@material-ui/icons";
import GraphQLTable from "components/GraphQL/GraphQLTable";
import {useIntl} from "react-intl";
import {useLocation} from "react-router-dom";

export default function SystemList(props) {
    const intl = useIntl();
    const location = useLocation();

    let customer_id = Number(props.match.params.customer_id);
    if (props.customer_id) {
        customer_id = props.customer_id;
    }

    const columns = [
        {title: intl.formatMessage({id:'systems.list.column.name', defaultMessage:'Name'}), field: 'name'},
        {title: intl.formatMessage({id:'systems.list.column.sender_name', defaultMessage:'Sender Name'}), field: 'sender_name'},
        {title: intl.formatMessage({id:'systems.list.column.is_active', defaultMessage:'Is Active'}), field: 'is_active', type: 'boolean', initial: false},
        {title: intl.formatMessage({id:'systems.list.column.channels', defaultMessage:'Channels'}), field: 'channel.name',
        render: row => row.channels ? row.channels.map(c => c.name).join(", ") : ''}
    ];
    if (!customer_id) {
        columns.unshift({
            title: intl.formatMessage({id:'systems.list.column.customer.name', defaultMessage:'Customer'}),
            field: 'customer.name',
            type: 'string',
            filtering: false,
            width: 'auto'
        });
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <GraphQLTable
                    inCard={!customer_id}
                    title={intl.formatMessage({id:'systems.list.table.heading', defaultMessage:"Systems list"})}
                    columns={columns}
                    query="systems"
                    mutations="system"
                    fields='id customer{name} channels{name} name sender_name is_active description'
                    filter={customer_id ? 'customer_id:' + customer_id : ''}
                    baseUri={customer_id ? location.pathname + '/systems' : ''}
                    icon={<Apps/>}
                    {...props}
                    options={customer_id ? {
                        pageSize: 5,
                        pageSizeOptions: [5,10,25],
                        ...props.options,
                    } : props.options}
                />
            </Grid>
        </Grid>
    );
}