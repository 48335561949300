import React from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// @material-ui/icons components
import Search from "@material-ui/icons/Search";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import NavbarDropdown from "components/Dropdowns/NavbarDropdown.js";

import getRoutesDef from "routes.js";

import componentStyles from "assets/theme/layouts/admin.js";
import {useIntl} from "react-intl";

const useStyles = makeStyles(componentStyles);

const Admin = ({history, match}) => {
  const intl = useIntl();
  const classes = useStyles();
  const location = useLocation();
  const routes = getRoutesDef(intl);
  const brandText = intl.formatMessage({id:"navbar.brand_text"});

  const getBreadcrumb = (routes, prefix = "", path = []) => {
    routes.forEach(r => {
      if (location.pathname.search(
          // Transform a path like /admin/products/:id/values/:id to something able to regexp:
          // like /admin/products/[0-9]+/values/[0-9]+
          String(prefix + r.path).replaceAll(new RegExp('/:[a-z_\-]+', 'g'), '/[0-9]+')
      ) === 0) {
        path.push({...r, href: prefix + r.path});
      }
      if (r.views) {
        path = getBreadcrumb(r.views, prefix + r.path, path);
      }
    });
    return path;
  };
  const breadcrumb = getBreadcrumb(routes, "/admin");

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // mainContent.current.scrollTop = 0;
    document.title = brandText + " - " + breadcrumb[breadcrumb.length-1].name;
  }, [location]);

  const getRoutes = (routes, prefix = "/admin") => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin" || prefix !== "/admin") {
        let rs = [];
        if (prop.views) {
          rs = getRoutes(prop.views, prefix + prop.path);
        }
        rs.push(<Route
            path={prefix + prop.path}
            component={prop.component}
            key={key}
        />);
        return rs;
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <>
        <Sidebar
          routes={routes}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("../assets/img/brand/logo-solid-black.png").default,
            imgAlt: brandText,
          }}
          dropdown={<NavbarDropdown />}
          input={
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-search-responsive">
                Search
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-search-responsive"
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <Box
                      component={Search}
                      width="1.25rem!important"
                      height="1.25rem!important"
                    />
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
          }
        />
        <Box position="relative" className={classes.mainContent}>
          <AdminNavbar breadcrumb={breadcrumb} history={history} match={match}/>
          <Container
            maxWidth={false}
            component={Box}
            classes={{ root: classes.containerRoot }}
          >
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/admin/index" />
            </Switch>
            <AdminFooter />
          </Container>
        </Box>
      </>
    </>
  );
};

export default Admin;
