import MaterialTable from "material-table";
import React, {useRef, useState} from "react";
import {graphQLApi} from "services/GraphQLApi";
import {useAuthDispatch} from "contexts/Auth";
import {useIntl} from "react-intl";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import {FilterListOutlined} from "@material-ui/icons";
import {Card, CardContent, Typography} from "@material-ui/core";

export default function GraphQLTable(props) {
  const intl = useIntl();
  const {
    mutations,
    addable = true,
    deletable = true,
    rowClickable = true,
    query,
    fields,
    columns,
    filter = "",
    inCard = true,
    filtering = true,
    softDeletes = false,
    pageSize = 100,
    baseUri = null,
    icon = null,
  } = props;

  const basePathUri = baseUri ? baseUri : props.history.location.pathname;

  const client = new graphQLApi(useAuthDispatch(), props.history);
  const tableRef = useRef();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmRow, setConfirmRow] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [showTrashed, setShowTrashed] = useState(false);

  const handleDelete = (event, row) => {
    let doDeletion = true;
    if (typeof deletable === "function") {
      doDeletion = deletable(row);
    }
    if (doDeletion) {
      setConfirmRow(row);
      setShowConfirmDialog(true);
    }
  };

  const updateTableOnRef = () => {
    if (props.tableRef) props.tableRef.current.onQueryChange();
    else tableRef.current.onQueryChange();
  };

  const handleConfirmation = (status) => {
    if (status) {
      client
        .mutate("($id:ID!) { " + mutations + "Delete(id:$id) }", {
          id: confirmRow.id,
        })
        .then((result) => {
          if (result === undefined || !result[mutations + "Delete"]) {
            return intl.formatMessage({
              id: "table.api.deletion-failed",
              defaultMessage: "Failed to delete the row",
            });
          }
          updateTableOnRef();
        });
    }
    setConfirmRow({});
    setShowConfirmDialog(false);
  };

  const handleRestore = (row) => {
    client
      .mutate("($id:ID!) { " + mutations + "Restore(id:$id) }", { id: row.id })
      .then((result) => {
        if (result === undefined || !result[mutations + "Restore"]) {
          return intl.formatMessage({
            id: "table.api.restore-failed",
            defaultMessage: "Failed to restore the row",
          });
        }
        updateTableOnRef();
      });
  };

  let actions = [];
  let rowClickAction = null;
  if (mutations) {
    if (showTrashed) {
      actions.push({
        icon: "undo",
        tooltip: intl.formatMessage({
          id: "common.tooltip.restore",
          defaultMessage: "Restore",
        }),
        onClick: (event, row) => handleRestore(row),
      });
    } else {
      if (rowClickable) {
        rowClickAction = (event, row) => {
          props.history.push(basePathUri + "/" + row.id);
        };
      }
      actions.push({
        icon: "edit",
        tooltip: intl.formatMessage({
          id: "common.tooltip.edit",
          defaultMessage: "Edit",
        }),
        onClick: (event, row) => props.history.push(basePathUri + "/" + row.id),
      });
      if (deletable) {
        actions.push({
          icon: "delete",
          tooltip: intl.formatMessage({
            id: "common.tooltip.delete",
            defaultMessage: "Delete",
          }),
          onClick: handleDelete,
        });
      }
    }
  }
  if (filtering) {
    actions.push({
      icon: FilterListOutlined,
      tooltip: intl.formatMessage({
        id: "common.tooltip.filter",
        defaultMessage: "Filter",
      }),
      isFreeAction: true,
      onClick: () => setShowFilter(!showFilter),
    });
  }
  if (softDeletes) {
    actions.push({
      icon: "history",
      tooltip: intl.formatMessage({
        id: "common.tooltip.show-deleted",
        defaultMessage: "Show deleted",
      }),
      isFreeAction: true,
      onClick: () => {
        setShowTrashed(!showTrashed);
        updateTableOnRef();
      },
    });
  }
  if (mutations && addable) {
    actions.push({
      icon: "add",
      tooltip: intl.formatMessage({
        id: "common.tooltip.add",
        defaultMessage: "Add",
      }),
      isFreeAction: true,
      onClick: () => props.history.push(basePathUri + "/create"),
    });
  }
  if (props.actions && !showTrashed) {
    props.actions.map((action) => actions.unshift(action));
  }

  return (
    <div>
      <ConfirmDialog
        title={intl.formatMessage({
          id: "common.dialog.delete.title",
          defaultMessage: "Confirm deletion",
        })}
        message={intl.formatMessage({
          id: "common.dialog.delete.message",
          defaultMessage: "Are you sure you want to delete this row?",
        })}
        open={showConfirmDialog}
        onClose={handleConfirmation}
      />
      <MaterialTable
        tableRef={props.tableRef ? props.tableRef : tableRef}
        data={(tableState) =>
          new Promise((resolve, reject) => {
            client
              .tableQuery(
                tableState,
                columns,
                query,
                fields,
                filter,
                showTrashed ? "trashed:true" : ""
              )
              .then((result) => {
                //console.log('Query result', result);
                if (result === undefined) {
                  return reject(
                    intl.formatMessage({
                      id: "table.api.no-data",
                      defaultMessage: "Failed to get any data",
                    })
                  );
                }
                resolve({
                  data: result ? result[query].data : [],
                  page: result ? result[query].current_page - 1 : 0,
                  totalCount: result ? result[query].total : 0,
                });
              });
          })
        }
        {...props}
        title={<Typography variant="h2">
          {props.title +
          (showTrashed
              ? " // " +
              intl.formatMessage({
                id: "common.table.showing_trashed",
                defaultMessage: "ONLY SHOWING TRASHED ITEMS",
              })
              : "")}</Typography>}
        header={false}
        options={{
          padding: "dense",
          pageSize: pageSize,
          pageSizeOptions: [10, 25, 50, 100, 500, 1000],
          actionsColumnIndex: -1,
          debounceInterval: 1700,
          filtering: filtering ? showFilter : false,
          ...props.options,
        }}
        components={{
          /*Toolbar: (props) =>
            inCard ? (
              <CardHeader
                  color="info"
                  avatar={icon}
                  style={{ color: themeColors.dark.main }}
                  title={<MTableToolbar {...props} />}
              />
            ) : (
              <MTableToolbar {...props} />
            ),*/
          Container: (props) =>
            inCard ? (
              <Card style={props.style}>
                {props.children[1]}
                <CardContent {...{ children: props.children.slice(2) }} />
              </Card>
            ) : (
              props.children
            ),
          ...props.components,
        }}
        actions={actions}
        onRowClick={rowClickAction}
      />
    </div>
  );
}
