import React from "react";
import {Grid, Paper} from "@material-ui/core";
import {Send} from "@material-ui/icons";
import GraphQLTable from "components/GraphQL/GraphQLTable";
import {useIntl} from "react-intl";
import ReactJson from "react-json-view";
import {Alert} from "@material-ui/lab";

export default function NotificationList(props) {
    const intl = useIntl();
    const columns = [
        {
            title: intl.formatMessage({id:'notifications.list.column.system.name', defaultMessage:'System'}),
            field: 'system.name',
            type: 'string',
            filtering: false,
            width: 'auto'
        },
        {title: intl.formatMessage({id:'notifications.list.column.external_id', defaultMessage:'External ID'}), field: 'external_id', type: 'numeric'},
        {
            title: intl.formatMessage({id:'notifications.list.column.channel.name', defaultMessage:'Channel'}), 
            field: 'channel.name', 
            type: 'string', 
            filtering: false,
            width: 'auto'
        },
        {title: intl.formatMessage({id:'notifications.list.column.recipient', defaultMessage:'Recipient'}), field: 'recipient', type: 'string'},
        {title: intl.formatMessage({id:'notifications.list.column.delivery_attempts', defaultMessage:'Attempts'}), field: 'delivery_attempts', type: 'numeric'},
        {
            title: intl.formatMessage({id:"notifications.list.column.delivered_at", defaultMessage:"Delivered At"}),
            field: "delivered_at", 
            type: "datetime",
        },
        {
            title: intl.formatMessage({id:"notifications.list.column.failed_at", defaultMessage:"Failed At"}),
            field: "failed_at",
            type: "datetime",
        },
        {
            title: intl.formatMessage({id:'notifications.list.column.created_at', defaultMessage:'Created'}),
            field: 'created_at', type: 'datetime', defaultSort: "desc",
        },
        {
            title: intl.formatMessage({id:'notifications.list.column.price', defaultMessage:'Price'}),
            field: 'price', type: 'numeric', render: row => intl.formatNumber(row.price, {minimumFractionDigits:6})
        },
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <GraphQLTable
                    {...props}
                    title={intl.formatMessage({id:'notifications.list.table.heading', defaultMessage:"Notifications"})}
                    columns={columns}
                    query="notifications"
                    fields='id system{name} external_id channel{name} system_token{id} message recipient delivered_at delivery_attempts price failed_at failure_reason created_at'
                    icon={<Send/>}
                    detailPanel={row => {
                        let failure = '';
                        if (row.failed_at) {
                            failure = (<Grid container spacing={1} style={{margin:"0.5em 0"}}>
                                {row.failure_reason.split('\n').map((m,k) => <Grid item xs={12}><Alert severity="error" key={'notification-row-'+row.id+'-'+k}>{m}</Alert></Grid>)}
                            </Grid>)
                        }
                        return (
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Paper variant="outlined" style={{padding:"0.5em",margin:"0.5em 0"}}>
                                        <ReactJson name={null} src={JSON.parse(row.message)}
                                                   collapseStringsAfterLength={75}
                                                   enableClipboard={false}/>
                                    </Paper>
                                </Grid>
                                <Grid item xs={6}>{failure}</Grid>
                            </Grid>
                        )
                    }}
                />
            </Grid>
        </Grid>
    );
}