import React from "react";
import {useIntl} from "react-intl";
import {Card, CardContent, CardHeader, Divider, Grid} from "@material-ui/core";
import {Apps} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";
import Comments from "../../../components/Comments/Comments";
import SystemTokenList from "./Tokens/SystemTokenList";

export default function SystemEdit(props) {
    const intl = useIntl();

    let id = props.match.params.system_id;
    let customer_id = props.match.params.customer_id;

    console.log(customer_id > 0 ? "" : "customer_id");

    const fields = [
        {
            field: "customer_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "systems.edit.label.customer_id", defaultMessage: "Customer"}),
            query: "customers",
            filter: "",
            titleField: "name"
        },
        {
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "systems.edit.label.name", defaultMessage: "Name"}),
            input: "text"
        },
        {
            field: "description",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "systems.edit.label.description", defaultMessage: "Description"}),
            input: "text",
            lines: 10
        },
        {
            column: 2,
            field: "is_active",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "systems.edit.label.is_active", defaultMessage: "Is Active"}),
            input: "switch"
        },
        {
            column: 2,
            field: "channels",
            initial: [],
            multiple: true,
            type: "[ID]",
            label: intl.formatMessage({id: "systems.edit.label.channels", defaultMessage: "Channels"}),
            query: "channels",
            titleField: "name",
            filter: (customer_id > 0 ? "customer_id:"+customer_id : ''),
            filterBy: (customer_id > 0 ? "" : "customer_id"),
        },
        {
            column: 2,
            field: "sender_name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "systems.edit.label.sender_name", defaultMessage: "Sender name"}),
            input: "text"
        },
        {
            column: 2,
            field: "sender_email",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "systems.edit.label.sender_email", defaultMessage: "Sender email"}),
            input: "text"
        },
        {
            column: 2,
            field: "allowed_ips",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "systems.edit.label.allowed_ips", defaultMessage: "Allowed IPs"}),
            input: "text"
        },
        {
            column: 2,
            field: "max_per_day",
            initial: 25,
            type: "Int",
            label: intl.formatMessage({id: "systems.edit.label.max_per_day", defaultMessage: "Limit notifications per day (0 to disable)"}),
            input: "number"
        },
    ];
    if (customer_id) {
        fields.shift();
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        color="primary"
                        avatar={<Apps/>}
                        title={intl.formatMessage({id: "systems.edit.heading", defaultMessage: "Systems edit"})}/>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <GraphQLEditForm id={id}
                                                 query={"systems"}
                                                 mutations={"system"}
                                                 fields={fields}
                                                 extraArgs={customer_id ? ",customer_id:"+customer_id : ""}
                                                 cols={2}
                                                 {...props}
                                />
                            </Grid>
                            {id ? <Grid item xs={12}>
                                <Divider/>
                            </Grid> : ''}
                            {id ? <Grid item xs={12}>
                                <SystemTokenList {...props} system_id={id}/>
                            </Grid> : ''}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            {id ? <Grid item xs={12}>
                <Comments systemId={id} history={props.history}/>
            </Grid> : ''}
        </Grid>
    );
}
