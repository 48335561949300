import React from "react";
// @material-ui/core components
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons components
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";

// core components
import componentStyles from "assets/theme/views/auth/login.js";
import {authLogin, getApiDomain, useAuthDispatch} from "contexts/Auth";
import {CardHeader, CircularProgress} from "@material-ui/core";
import {useIntl} from "react-intl";

const useStyles = makeStyles(componentStyles);
let checkedDomain = false;

function Login(props) {
    const intl = useIntl();
    const dispatch = useAuthDispatch();

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);

    const validationFields = {email: "", password: "", message: ""};
    const [validation, setValidation] = React.useState(validationFields);
    const [domainCheck, setDomainCheck] = React.useState(null);

    React.useEffect(() => {
        if (domainCheck === null) {
            console.log("Checking api domain", getApiDomain());
            fetch(getApiDomain(), {}).then((response) => {
                if (response.status === 200) {
                    return response.text().then((text) => {
                        if (text.indexOf("OpenNotify") !== -1 &&
                            text.indexOf("TINX/DK A/S") !== -1) {
                            setDomainCheck(true);
                        } else {
                            setDomainCheck(false);
                            console.error("Response from api check did not validate", text);
                        }
                    });
                } else {
                    setDomainCheck(false);
                }
            }).catch(reason => {
                console.error('Failed to validate the API domain', getApiDomain(), reason);
                setDomainCheck(false);
            });
        }
    });

    const getValidation = (apiValidation) => {
        let errors = {};
        if (apiValidation.message === "Unauthorized") {
            errors.email = "Please recheck";
            errors.password = "Please recheck";
            errors.message =
                "Email and/or password seems to be wrong, pleser check and try again.";
        }
        errors = {...validationFields, ...apiValidation, ...errors};
        setValidation(errors);
        //console.log('getValidation', apiValidation, errors, validation);
    };

    const performLogin = (e) => {
        e.preventDefault();
        authLogin(dispatch, props.history, setIsLoading, email, password, getValidation);
    };

    const classes = useStyles();
    const theme = useTheme();
    return (
        <>
            <Grid item xs={12} lg={5} md={7}>
                {domainCheck === null ? (
                    <CircularProgress/>
                ) : domainCheck === false ? (
                    <Card color="danger">
                        <CardHeader
                            avatar={<i className={"ni ni-bell-55"}/>}
                            title={intl.formatMessage({
                                id: "login.domain-is-incorrect.header",
                                defaultMessage: "Domain is not correct"
                            })}
                            subheader={window.location.hostname}
                        />
                        <CardContent>
                            {intl.formatMessage({
                                id: "login.domain-is-incorrect.body",
                                defaultMessage: "It appears that this domain is not configured, have you entered the correct URL?"
                            })}
                        </CardContent>
                    </Card>
                ) : (<form onSubmit={performLogin}>
                    <Card classes={{root: classes.cardRoot}}>
                        <CardContent classes={{root: classes.cardContent}}>
                            <FormControl
                                variant="filled"
                                component={Box}
                                width="100%"
                                marginBottom="1rem!important"
                            >
                                <FilledInput
                                    autoComplete="off"
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    error={validation.email.length > 0}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Email/>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <FormControl
                                variant="filled"
                                component={Box}
                                width="100%"
                                marginBottom="1rem!important"
                            >
                                <FilledInput
                                    autoComplete="off"
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    error={validation.password.length > 0}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Lock/>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <FormControlLabel
                                value="end"
                                control={<Checkbox color="primary"/>}
                                label="Remeber me"
                                labelPlacement="end"
                                classes={{
                                    root: classes.formControlLabelRoot,
                                    label: classes.formControlLabelLabel,
                                }}
                            />
                            <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                                {isLoading ?
                                    <CircularProgress/>
                                    :
                                    <Button color="primary" variant="contained" onClick={performLogin}>
                                        Sign in
                                    </Button>
                                }
                            </Box>
                        </CardContent>
                    </Card>
                    {/*<Grid container component={Box} marginTop="1rem">
                        <Grid item xs={6} component={Box} textAlign="left">
                            <a
                                href="#admui"
                                onClick={(e) => e.preventDefault()}
                                className={classes.footerLinks}
                            >
                                Forgot password
                            </a>
                        </Grid>
                        <Grid item xs={6} component={Box} textAlign="right">
                            <a
                                href="#admui"
                                onClick={(e) => e.preventDefault()}
                                className={classes.footerLinks}
                            >
                                Create new account
                            </a>
                        </Grid>
                    </Grid>*/}
                </form>)}
            </Grid>
        </>
    );
}

export default Login;
